body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif, 'SVN-Gilroy';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    min-width: 1024px;
}

* {
    padding: 0;
    margin: 0;
}

#root {
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Bold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Bold Italic.otf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Italic.otf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Light.otf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Light Italic.otf') format('truetype');
    font-weight: lighter;
    font-style: italic;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}